@use '@on3/styles/variables' as var;

@use '@on3/styles/mixins' as mix;

@include mix.homelayout;

.sticky {
  position: sticky;
  top: 114px;

  @media only screen and (min-width: 1140px) {
    top: 140px;
  }
}

.metaIcon {
  height: 36px;
  width: 80px;
}
